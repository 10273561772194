// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardList, IconClipboardCheck, IconWriting } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList: IconClipboardList,
    IconClipboardCheck: IconClipboardCheck,
    IconWriting: IconWriting
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const billingMenuItems = {
    id: 'billing-pages',
    type: 'group',
    title: <FormattedMessage id="Billing" />,
    children: [
        {
            id: 'billing-history-page',
            title: <FormattedMessage id="Billing list" />,
            type: 'item',
            roles: ['super-admin', 'client'],
            url: '/billing/history',
            icon: icons['IconClipboardCheck'],
            breadcrumbs: false
        },
        {
            id: 'billing-management-page',
            title: <FormattedMessage id="Billing managment" />,
            type: 'item',
            roles: ['super-admin'],
            url: '/billing/management',
            icon: icons['IconClipboardCheck'],
            breadcrumbs: false
        }
    ]
};
