// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconHelp, IconSitemap, IconSearch } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const main = {
    id: 'main',
    type: 'group',
    children: [
        {
            id: 'dashboard-page',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons['IconDashboard'],
            breadcrumbs: false
        },
        {
            id: 'search-page',
            title: <FormattedMessage id="Search package" />,
            type: 'item',
            url: '/package/search',
            icon: IconSearch,
            breadcrumbs: false
        }
    ]
};
