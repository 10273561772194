import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import config from './../config';
import imageSuivipost from './../assets/images/suivipost/logo.png';
import imageSuivipostPrint from './../assets/images/suivipost/logo-print.png';
import imageJJDelivery from './../assets/images/jj-delivery/logo.png';
import imageJJDeliveryPrint from './../assets/images/jj-delivery/logo-print.png';
import imageEaglemen from './../assets/images/eaglemen/logo.png';
import imageEaglemenPrint from './../assets/images/eaglemen/logo-print.png';
import imageSonic from './../assets/images/sonic/logo.png';
import imageSonicPrint from './../assets/images/sonic/logo-print.png';

const images = {
    'suivipost': {
        'logo': imageSuivipost,
        'logo-print': imageSuivipostPrint,
        'width': 130
    },
    'jj-delivery': {
        'logo': imageSuivipost,
        'logo-print': imageJJDeliveryPrint,
        'width': 130
    },
    'eaglemen': {
        'logo': imageEaglemen,
        'logo-print': imageEaglemenPrint,
        'width': 85
    },
    'sonic': {
        'logo': imageSonic,
        'logo-print': imageSonicPrint,
        'width': 85
    }
}

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = ({ logo, width }) => {
    const image = images[config.clientIdentifier]
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Suivi Post" width="100" />
         *  <h1 style={{ marginLeft: '12px' }}>Suivi Post</h1>
         *
         */
        <>
            <img src={image[logo]}
                alt="Suivi Post" width={image.width ? image.width : width} />
        </>
    );
};

export default Logo;
