import React from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from '@material-ui/core';

// animation
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//===============================|| UI DIALOG - SLIDE ANIMATION ||===============================//

export default function AlertDialogSlide({
    handleClose,
    handleConfirm,
    handleConfirmParams,
    open,
    title,
    description,
    confirmButtonText,
    cancelButtonText
}) {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title1"
            aria-describedby="alert-dialog-slide-description1"
        >
            <DialogTitle id="alert-dialog-slide-title1">
                <Typography variant="h4">{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description1">
                    <Typography variant="body2">
                        {description}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pr: 2.5 }}>
                {!!cancelButtonText && <Button onClick={handleClose} color="error">
                    {cancelButtonText}
                </Button>}
                {!!confirmButtonText && <Button variant="contained" size="small" onClick={() => handleConfirm(handleConfirmParams)} color="primary">
                    {confirmButtonText}
                </Button>}
            </DialogActions>
        </Dialog>
    );
}
