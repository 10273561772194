// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardList, IconClipboardCheck, IconWriting, IconReport } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList: IconClipboardList,
    IconClipboardCheck: IconClipboardCheck,
    IconWriting: IconWriting,
    IconReport: IconReport
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const packageMenuItems = {
    id: 'package-pages',
    type: 'group',
    title: <FormattedMessage id="package managment" />,
    children: [
        {
            id: 'add-package-page',
            title: <FormattedMessage id="Add package" />,
            type: 'item',
            url: '/package/create',
            roles: ['super-admin', 'depot_manager', 'client'],
            icon: icons['IconWriting'],
            breadcrumbs: false
        },
        {
            id: 'import-parcels-page',
            title: <FormattedMessage id="Import parcels in bulk" />,
            type: 'item',
            url: '/package/import',
            roles: ['super-admin', 'depot_manager', 'client'],
            icon: icons['IconWriting'],
            breadcrumbs: false
        },
        {
            id: 'list-packages-page',
            title: <FormattedMessage id="List packages" />,
            type: 'item',
            url: '/package/list?payment_status=UNPAID',
            icon: icons['IconClipboardCheck'],
            breadcrumbs: false
        },
        {
            id: 'delivery-manifest-page',
            title: <FormattedMessage id="Delivery manifest" />,
            type: 'item',
            url: '/package/delivery/manifest',
            icon: icons['IconReport'],
            breadcrumbs: false
        }
    ]
};
