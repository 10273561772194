import axios from "axios";


export const authClient = axios.create({
    baseURL: process.env.REACT_APP_APP_API_URL,
    withCredentials: true
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        console.error(error, error.response)
        // if (error.response.status === 401) {
        //     window.location.href = "/login"
        // }
        return Promise.reject(error);
    }
);

const AuthService = {
    async login(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/login", payload);
    },
    logout() {
        return authClient.post("/logout");
    },
    async forgotPassword(payload) {
        await authClient.get("/sanctum/csrf-cookie");
        return authClient.post("/forgot-password", payload);
    },
    getAuthUser() {
        return authClient.get("/api/user");
    },
    async isAuthenticated() {
        let isAuthenticated = false;
        await this.getAuthUser().then(() => {
            isAuthenticated = true;
        })
            .catch(() => {
                isAuthenticated = false;
            })
        return isAuthenticated;
    }
};

export default AuthService;