// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardList, IconClipboardCheck, IconWriting, IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList: IconClipboardList,
    IconClipboardCheck: IconClipboardCheck,
    IconWriting: IconWriting
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const accountMenuItems = {
    id: 'account-pages',
    type: 'group',
    title: <FormattedMessage id="Account management" />,
    children: [
        {
            id: 'create-account-page',
            title: <FormattedMessage id="Create Account" />,
            type: 'item',
            roles: ['super-admin'],
            url: '/account/create',
            icon: icons['IconWriting'],
            breadcrumbs: false
        },
        {
            id: 'list-accounts-page',
            title: <FormattedMessage id="List Accounts" />,
            type: 'item',
            roles: ['super-admin'],
            url: '/account/list',
            icon: icons['IconClipboardCheck'],
            breadcrumbs: false
        },
        {
            id: 'my-account',
            title: <FormattedMessage id="My Account" />,
            type: 'item',
            url: '/account/profile',
            icon: IconSettings,
            breadcrumbs: false
        }
    ]
};
