import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import RoleGuard from './../utils/route-guard/RoleGuard';

// Profile Page routing
const CreateAccountPage = Loadable(lazy(() => import('../views/pages/account/create')));
// Profile Page routing
const UpdateAccountPage = Loadable(lazy(() => import('../views/pages/account/update')));
// Profile Page routing
const ListAccountPage = Loadable(lazy(() => import('../views/pages/account/list')));
// Profile Page routing
const ProfilePage = Loadable(lazy(() => import('../views/pages/account/profile')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const AccountRoutes = () => {
    const location = useLocation();

    return (
        <Route path={[
            '/account/profile',
            '/account/create',
            '/account/:id/update',
            '/account/list'
        ]}>
            <MainLayout showBreadcrumb={true}>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/account/create" render={(props) => <CreateAccountPage {...props} title={'Création du compte'} />} />
                        <Route path="/account/:id/update" render={(props) => <UpdateAccountPage {...props} title={'Modification du compte'} />} />
                        <Route path="/account/list" render={(props) => <ListAccountPage {...props} title={'Liste des comptes'} />} />
                        <Route path="/account/profile" render={(props) => <ProfilePage {...props} title={'Détails du compte'} />} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default AccountRoutes;
