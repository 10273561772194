import React, { createContext, useEffect, useReducer } from 'react';


// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from '../store/actions';
import accountReducer from '../store/accountReducer';


import Loader from '../ui-component/Loader';
import authService from '../services/AuthService';

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

//-----------------------|| AUTH0 CONTEXT & PROVIDER ||-----------------------//

const AuthContext = createContext({
    ...initialState,
    logout: () => { },
    login: () => { },
    getUser: () => { },
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const logout = () => {
        authService.logout();
        dispatch({
            type: LOGOUT
        });
    };
    const getUser = () => {
        authService.getAuthUser().then((response) => {
            dispatch({
                type: ACCOUNT_INITIALIZE,
                payload: {
                    isLoggedIn: true,
                    user: {
                        ...response.data
                    }
                }
            });
        })

    };
    const login = async (payload) => {
        try {
            const response = await authService.login(payload);
            dispatch({
                type: LOGIN,
                payload: {
                    user: {
                        ...response.data
                    }
                }
            });
        } catch (error) {
            dispatch({
                type: LOGOUT,
                payload: {
                    isLoggedIn: false,
                    user: null
                }
            });
            throw new Error(error.response.data?.errors?.email[0])
        }
        /*authService.login(payload).then((response) => {
            dispatch({
                type: LOGIN,
                payload: {
                    user: {
                        ...response.data
                    }
                }
            });
        })
            .catch((error) => {
                dispatch({
                    type: LOGOUT,
                    payload: {
                        isLoggedIn: false,
                        errorMessage: '',
                        user: null
                    }
                });
            })*/
    }

    useEffect(() => {
        const init = async () => {
            try {
                const isLoggedIn = await authService.isAuthenticated();

                if (isLoggedIn) {
                    const response = await authService.getAuthUser();

                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                ...response.data
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <AuthContext.Provider value={{ ...state, logout, login, getUser }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
