import { Description } from "@material-ui/icons";

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light',
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'fr',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    clientIdentifier: process.env.REACT_APP_CLIENT_IDENTIFIER,
    clientConfig: {
        'suivipost': {
            name: 'STE Suivipost',
            blocked: false,
            address: '01 rue exemple Monastir',
            phone: '## ### ###',
            taxNumber: '######/#',
            alert: {
                show: false,
                viewedOnce: false,
                title: 'Aïd Moubarak!',
                description: "L'équipe Suivipost vous souhaitent, à vous et à votre famille, un joyeux Aïd.",
                descriptionStrong: ""
            },
            notifForAdmin: {
                show: false,
                viewedOnce: false,
                title: 'Notification concernant les factures impayées et la désactivation du compte',
                description: "Nous espérons que vous allez bien. Nous tenons à vous informer qu'il reste des factures impayées liées à votre compte chez Suivipost. Nous comprenons que des circonstances imprévues peuvent parfois entraîner des retards de paiement, cependant, nous devons vous rappeler l'importance de régler ces factures afin de maintenir votre compte actif et en règle",
                descriptionStrong: "Cependant, veuillez noter que si les factures impayées ne sont pas réglées d'ici le 6 septembre 2023, nous serons contraints de désactiver temporairement votre compte. Cette désactivation pourrait entraîner une interruption de nos services, ainsi que d'autres conséquences potentielles, telles que des frais de retard.",
                descriptionFooter:"Nous vous remercions de votre attention à cette question. Nous espérons que vous prendrez les mesures nécessaires pour régler ces factures en suspens. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter dès que possible."
            },
            repositories: [
                {
                    label: 'Dépôt de Gabes',
                    value: 'repository_gabes'
                },
                {
                    label: 'Dépôt de Mednine',
                    value: 'repository_mednine'
                },
                {
                    label: 'Dépôt de Gafsa',
                    value: 'repository_gafsa'
                },
                {
                    label: 'Dépôt de Sfax',
                    value: 'repository_sfax'
                },
                {
                    label: 'Dépôt de Sousse',
                    value: 'repository_sousse'
                },
                {
                    label: 'Dépôt de Tunis',
                    value: 'repository_tunis'
                },
                {
                    label: 'Dépôt de Béja',
                    value: 'repository_beja'
                }
            ]
        },
        'eaglemen': {
            name: 'STE EagleMen Logistic',
            blocked: false,
            address: 'Essad Mahdia 5127',
            phone: '98178023',
            taxNumber: '17645530/B/A/M/000',
            alert: {
                show: false,
                viewedOnce: false,
                title: 'Aïd Moubarak!',
                description: "Les équipes Eaglemen et Suivipost vous souhaitent, à vous et à votre famille, un joyeux Aïd.",
                descriptionStrong: "Nous allons reprendre les Pickup le dimanche 23/04/2023"
            },
            notifForAdmin: {
                show: false,
                viewedOnce: false,
                title: 'Notification concernant les factures impayées et la désactivation du compte',
                description: "Nous espérons que vous allez bien. Nous tenons à vous informer qu'il reste des factures impayées liées à votre compte chez Suivipost. Nous comprenons que des circonstances imprévues peuvent parfois entraîner des retards de paiement, cependant, nous devons vous rappeler l'importance de régler ces factures afin de maintenir votre compte actif et en règle",
                descriptionStrong: "Cependant, veuillez noter que si les factures impayées ne sont pas réglées d'ici le 6 septembre 2023, nous serons contraints de désactiver temporairement votre compte. Cette désactivation pourrait entraîner une interruption de nos services, ainsi que d'autres conséquences potentielles, telles que des frais de retard.",
                descriptionFooter:"Nous vous remercions de votre attention à cette question. Nous espérons que vous prendrez les mesures nécessaires pour régler ces factures en suspens. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter dès que possible."
            },
            repositories: [
                {
                    label: 'Dépôt de Sousse',
                    value: 'repository_sousse'
                },
                {
                    label: 'Dépôt de Tunis',
                    value: 'repository_tunis'
                },
                {
                    label: 'Dépôt de Sfax',
                    value: 'repository_sfax'
                },]
        },
        'rapide-colis': {
            name: 'STE Rapide Colis',
            blocked: false,
            address: '####',
            phone: '23019269',
            taxNumber: '####',
            alert: {
                show: false,
                viewedOnce: false,
                title: 'Aïd Moubarak!',
                description: "Les équipes Eaglemen et Suivipost vous souhaitent, à vous et à votre famille, un joyeux Aïd.",
                descriptionStrong: "Nous allons reprendre les Pickup le dimanche 23/04/2023"
            },
            notifForAdmin: {
                show: false,
                viewedOnce: false,
                title: 'Notification concernant les factures impayées et la désactivation du compte',
                description: "Nous espérons que vous allez bien. Nous tenons à vous informer qu'il reste des factures impayées liées à votre compte chez Suivipost. Nous comprenons que des circonstances imprévues peuvent parfois entraîner des retards de paiement, cependant, nous devons vous rappeler l'importance de régler ces factures afin de maintenir votre compte actif et en règle",
                descriptionStrong: "Cependant, veuillez noter que si les factures impayées ne sont pas réglées d'ici le 6 septembre 2023, nous serons contraints de désactiver temporairement votre compte. Cette désactivation pourrait entraîner une interruption de nos services, ainsi que d'autres conséquences potentielles, telles que des frais de retard.",
                descriptionFooter:"Nous vous remercions de votre attention à cette question. Nous espérons que vous prendrez les mesures nécessaires pour régler ces factures en suspens. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter dès que possible."
            },
            repositories: [
                {
                    label: 'Dépôt de Tunis',
                    value: 'repository_tunis'
                }]
        },
        'jj-delivery': {
            name: 'STE J and J Delivery',
            blocked: true,
            address: '011, Abou Ali Alfarsi, Ibn Sina, Tunis',
            phone: '23 823 535',
            taxNumber: '1725158/J',
            alert: {
                show: false,
                viewedOnce: false,
                title: 'Aïd Moubarak!',
                description: "Les équipes JJ delivery et Suivipost vous souhaitent, à vous et à votre famille, un joyeux Aïd.",
                descriptionStrong: "Nous allons reprendre les Pickup le lundi 24/04/2023 et les livraisons le mardi 25/04/2023."
            },
            notifForAdmin: {
                show: false,
                viewedOnce: false,
                title: 'Notification concernant les factures impayées et la désactivation du compte',
                description: "Nous espérons que vous allez bien. Nous tenons à vous informer qu'il reste des factures impayées liées à votre compte chez Suivipost. Nous comprenons que des circonstances imprévues peuvent parfois entraîner des retards de paiement, cependant, nous devons vous rappeler l'importance de régler ces factures afin de maintenir votre compte actif et en règle",
                descriptionStrong: "Cependant, veuillez noter que si les factures impayées ne sont pas réglées d'ici le 6 septembre 2023, nous serons contraints de désactiver temporairement votre compte. Cette désactivation pourrait entraîner une interruption de nos services, ainsi que d'autres conséquences potentielles, telles que des frais de retard.",
                descriptionFooter:"Nous vous remercions de votre attention à cette question. Nous espérons que vous prendrez les mesures nécessaires pour régler ces factures en suspens. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter dès que possible."
            },
            repositories: [
                {
                    label: 'Dépôt de Gabes',
                    value: 'repository_gabes'
                },
                {
                    label: 'Dépôt de Mednine',
                    value: 'repository_mednine'
                },
                {
                    label: 'Dépôt de Gafsa',
                    value: 'repository_gafsa'
                },
                {
                    label: 'Dépôt de Sfax',
                    value: 'repository_sfax'
                },
                {
                    label: 'Dépôt de Sousse',
                    value: 'repository_sousse'
                },
                {
                    label: 'Dépôt de Tunis',
                    value: 'repository_tunis'
                },
                {
                    label: 'Dépôt de Béja',
                    value: 'repository_beja'
                }
            ]
        }
    }
};

export default config;
