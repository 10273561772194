import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import RoleGuard from './../utils/route-guard/RoleGuard';

// dashboard routing
const DashboardPage = Loadable(lazy(() => import('../views/pages/dashboard')));
// CreatePackagePage routing
const CreatePackagePage = Loadable(lazy(() => import('../views/pages/package/create')));
// PackagesListPage routing
const ListPackagesPage = Loadable(lazy(() => import('../views/pages/package/list')));
// Package details routing
const PackageDetails = Loadable(lazy(() => import('../views/pages/package/details')));
// Package update routing
const UpdatePackagePage = Loadable(lazy(() => import('../views/pages/package/update')));

const ImportParcelsPage = Loadable(lazy(() => import('../views/pages/package/import')));
// Package reporting routing
const ReportingPackagePage = Loadable(lazy(() => import('../views/pages/package/delivery-manifest')));
// Package reporting routing
const DeliveryStatusPage = Loadable(lazy(() => import('../views/pages/tour/delivery-status')));
const ValidateTourPage = Loadable(lazy(() => import('../views/pages/tour/validate')));
// Tour creation routing
const TourCreation = Loadable(lazy(() => import('../views/pages/tour/create')));
// Tour creation routing
const TourList = Loadable(lazy(() => import('../views/pages/tour/list')));
// Tour creation routing
const TourDetailsPage = Loadable(lazy(() => import('../views/pages/tour/details')));
// Tour creation routing
const TourUpdatePage = Loadable(lazy(() => import('../views/pages/tour/update')));
// Tour creation routing
const BillingHistoryPage = Loadable(lazy(() => import('../views/pages/billing/list')));
const BillingManagementPage = Loadable(lazy(() => import('../views/pages/billing/management')));
const BillingDetailsPage = Loadable(lazy(() => import('../views/pages/billing/details')));
// Search Page routing
const SearchPage = Loadable(lazy(() => import('../views/pages/search')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route path={[
            '/dashboard', '/package/create',
            '/package/list', '/package/manifest',
            '/package/:id/details', '/package/:id/update',
            '/package/search','/package/import', '/package/delivery/manifest',
            '/tour/create', '/tour/list', '/tour/:id/details',
            '/tour/delivery/status', '/billing/management',
            '/tour/:id/update', '/billing/history', '/billing/:id/details',
            '/tour/:id/validate'
        ]}>
            <MainLayout showBreadcrumb={true}>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard" render={(props) => <DashboardPage {...props} title={'Dashboard'} />} />
                        <Route path="/package/create" render={(props) => <CreatePackagePage {...props} title={'Création du colis'} />} />
                        <Route path="/package/list" render={(props) => <ListPackagesPage {...props} title={'Liste des colis'} />} />
                        <Route path="/package/:id/details" render={(props) => <PackageDetails {...props} title={'Détails du colis'} />} />
                        <Route path="/package/:id/update" render={(props) => <UpdatePackagePage {...props} title={'Modification du colis'} />} />
                        <Route path="/package/search" render={(props) => <SearchPage {...props} title={'Recherche'} />} />
                        <Route path="/package/import" render={(props) => <ImportParcelsPage {...props} title={'importer des colis'} />} />
                        <Route path="/package/delivery/manifest" render={(props) => <ReportingPackagePage {...props} title={'Manifeste du livraison'} />} />
                        <Route path="/tour/delivery/status" render={(props) => <DeliveryStatusPage {...props} title={'Mettre à jour le statut de livraison'} />} />
                        <Route path="/tour/create" render={(props) => <TourCreation {...props} title={'Création du tournée'} />} />
                        <Route path="/tour/list" render={(props) => <TourList {...props} title={'Liste des tournée'} />} />
                        <Route path="/tour/:id/details" render={(props) => <TourDetailsPage {...props} title={'Détails de la tournée'} />} />
                        <Route path="/billing/history" render={(props) => <BillingHistoryPage {...props} title={'Liste des factures'} />} />
                        <Route path="/billing/management" render={(props) => <BillingManagementPage {...props} title={'Gestion de la facturation'} />}/>
                        <Route path="/billing/:id/details" render={(props) => <BillingDetailsPage {...props} title={'Détails de la facturation'} />}/>
                        <Route path="/tour/:id/update" render={(props) => <TourUpdatePage {...props} title={'Modification des tournées'} />}/>
                        <Route path="/tour/:id/validate" render={(props) => <ValidateTourPage {...props} title={'Validation des tournées'} />}/>
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
