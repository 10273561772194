import { main } from './main';
import { packageMenuItems } from './package';
import { accountMenuItems } from './account';
import { tourMenuItems } from './tour';
import { billingMenuItems } from './billing';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [
        main,
        tourMenuItems,
        packageMenuItems,
        accountMenuItems,
        billingMenuItems
    ]
};

export default menuItems;
