import React from 'react';
import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

// load mock apis
import './_mockApis';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import reducer from './store/reducer';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// style + assets
import './assets/scss/style.scss';


//-----------------------|| REACT DOM RENDER  ||-----------------------//
Sentry.init({
    dsn: "https://cf81400414e54709ab3b3544cbec0610@o1006084.ingest.sentry.io/5966549",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.2
});

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
