import { useContext } from 'react';

// auth provider
import AuthContext from '../contexts/AuthContext';
// import Auth0Context from '../contexts/Auth0Context';
// import JWTContext from '../contexts/JWTContext';

//-----------------------|| AUTH HOOKS ||-----------------------//

const useAuth = () => useContext(AuthContext);

export default useAuth;
