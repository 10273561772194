// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardList, IconClipboardCheck, IconWriting, IconReport } from '@tabler/icons';

// constant
const icons = {
    IconClipboardList: IconClipboardList,
    IconClipboardCheck: IconClipboardCheck,
    IconWriting: IconWriting,
    IconReport: IconReport
};

//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const tourMenuItems = {
    id: 'tour-management-pages',
    type: 'group',
    title: <FormattedMessage id="Tour management" />,
    children: [
        {
            id: 'plan-tour-page',
            title: <FormattedMessage id="Plan a tour" />,
            type: 'item',
            roles: ['super-admin', 'depot_manager'],
            url: '/tour/create',
            icon: icons['IconWriting'],
            breadcrumbs: false
        },
        {
            id: 'list-tour-page',
            title: <FormattedMessage id="Tour list" />,
            type: 'item',
            roles: ['super-admin', 'depot_manager', 'transporter'],
            url: '/tour/list',
            icon: icons['IconClipboardCheck'],
            breadcrumbs: false
        },
        {
            id: 'delivery-status-page',
            title: <FormattedMessage id="Update delivery status" />,
            type: 'item',
            url: '/tour/delivery/status',
            roles: ['super-admin', 'depot_manager', 'transporter'],
            icon: icons['IconReport'],
            breadcrumbs: true
        }
    ]
};
